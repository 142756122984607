////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(233, 233, 233);
}
.bg-secondaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-thirdColor {
  background-color: rgb(255, 255, 255);
}
.bg-fourthColor {
  background-color: rgb(165, 192, 66);
}
.bg-fifthColor {
  background-color: rgb(216, 236, 142);
}
.bg-sixthColor {
  background-color: rgba(53, 53, 53, 0.71);
}
.bg-seventhColor {
  background-color: rgb(233, 233, 233);
}
